import React from "react";
import Const from './Const';
import { messageModalWindow } from './Utils.js';

class ImageUpload extends React.Component {
	constructor(props) {
		super(props);
			this.state = {
				file: '',
				imagePreviewUrl: '',
				isError: false,
				messageModal : false,
				header : '',
			    text : '',
				parent : null
			};
	}

	disableElements() { 
		let imageDiv = document.getElementById('imageDiv');

		imageDiv.style.opacity = Const.REDUCED_OPACITY;
	}

	render() {
		let {imagePreviewUrl} = this.state;
		
		return (
			<>
			<div className="previewComponent">
				<div id="imageDiv" className="imgPreview">
					{
					  imagePreviewUrl &&
					  <div className="image-parent-create-post">
					  	<img alt="your upload" src={imagePreviewUrl} className="img-fluid"/>
					  </div>
					}
				</div>
			</div>
			<div>
				{
				  messageModalWindow(this, this.state.header, this.state.text)
				}
			</div>
			</>
		)
	}
}

export default ImageUpload;