import { Row, Col } from 'reactstrap';
import { isDesktop } from './AppUtils.js';
import { remainingChars } from '../components/Utils.js';
import EmojiPicker from 'emoji-picker-react';

export function getEmojiPicker(that) {
  if (isDesktop()) {
    return ( 
      <EmojiPicker onEmojiClick={that.handleEmojiClick} />
    )
  }
  else {
    return ( 
      null
    )
  }
}

export function getBottomBar(charCounter, that) {
  if (isDesktop()) {
    return getBottomBarDeskTop(charCounter, that);
  }
  else {
    return getBottomBarMobilDevice(charCounter, that);
  }
}

export function getBottomBarDeskTop(charCounter, that) {
  return ( 
    <Row className="media1 row-margin-01" > 			 
      <Col className="p-0" md={ getUnitsNumberFirstColumn() } xs={6}>
        <Row>
          <Col>
            {
              that.getUploadImageIckon()
            }
          </Col>
          <Col className='marginLeft'>
            {
             that.getEmoji()
            }
          </Col>
          <Col className='marginLeft'>
            {
              that.getToAIIckon()
            }
          </Col>
        </Row>
      </Col>
      <Col className="p-0" md={ getUnitsNumberSecondColumn() } xs={6}>           
        {
          remainingChars(charCounter)
        }
      </Col>				
    </Row> 
  ) 
}

export function getBottomBarMobilDevice(charCounter, that) {
  // Any mobil device
  return ( 
    <Row className="media1 row-margin-01" > 			 
      <Col className="p-0" md={ getUnitsNumberFirstColumn() } xs={6}>
        {
          that.getUploadImageIckon()
        }

        {
          that.getToAIIckon()
        }
      </Col>
      <Col className="p-0" md={ getUnitsNumberSecondColumn() } xs={6}>           
        {
          remainingChars(charCounter)
        }
      </Col>				
    </Row> 
  ) 
}

export function getUnitsNumberFirstColumn() {
  let unitsNumber;

  if (isDesktop()) {
    unitsNumber = 3;
  }
  else {
    unitsNumber = 2;
  }
  return unitsNumber;
}

export function getUnitsNumberSecondColumn() {
  let unitsNumber;

  if (isDesktop()) {
    unitsNumber = 9;
  }
  else {
    unitsNumber = 10;
  }
  return unitsNumber;
}

export function getMarginLeftClassName() {
  let marginLeftClassName = '';

  if (isDesktop()) {
    marginLeftClassName = 'marginLeft';
  }
  else {
    marginLeftClassName = '';
  }
  return marginLeftClassName;
}

export function emojiCloseClickHandler(e, that) {
  let showEmojiEmojiPicker;
  let className;
  let cond1;
  let cond2;

  if (isDesktop()) {
    showEmojiEmojiPicker = that.state.showEmojiEmojiPicker;
    className = e.target.className;
    cond1 = (typeof className === 'string') ? (className === '') : true;
    cond2 = (typeof className === 'string') ? !(className.indexOf('epr') > -1) : true;
    if (showEmojiEmojiPicker && (cond1 || cond2)) {
      that.toggleEmojiEmojiPicker();
    }
  }
}

export function postsAndFollowersClassNames(containerName) {
  // consierge / consierge-desktop for browsing AI image is set not here.
  // consierge-desktop is set in ProfileOfLoginUser.getClassNameProfileBlock()
  // consiergeis set in ProfileOfLoginUser.getClassNameContainer().
  // "nav-display-desktop" is set in UniversalSearch.getNavOrSearchBoxOnTop()

  let isDesktopCond = isDesktop();
  let classNames = {
      postsDiv : isDesktopCond ? 'post-div-desktop' : 'post-div',
      postsDivProfile : isDesktopCond ? 'post-div-profile-desktop' : 'post-div-profile',
      rightDiv : isDesktopCond ? 'right-div-desktop' : 'right-div',
      rightDivProfile : isDesktopCond ? 'right-div-profile-desktop' : 'right-div-profile',
      scroll : isDesktopCond ? 'scroll-desktop' : 'scroll',
      afterAppWrapCenterDiv : isDesktopCond ? containerName + '-desktop' : containerName,
      bodyFullscreen3 : isDesktopCond ? 'body-fullscreen-3-desktop' : 'body-fullscreen-3',
      sugested : isDesktopCond ? 'suggested-desktop': 'suggested',
      username : isDesktopCond ? 'username-desktop': 'username',
      tagline : isDesktopCond ? 'tagline-desktop': 'tagline',
      iFollow : isDesktopCond ? 'ifollow-desktop': 'ifollow'
    }

	return classNames;
}