import React from 'react';
import './css/bootstrap.css';
import './css/tablet.css';

import './App.css';
import './css/ai-intro-image.css';
import './css/App-advanced.css';
import './css/Tooltip.css';
import './css/App-dark-preferences.css';
import './css/CustomDropdown.css';
import './css/CustomDropdownSearchParms.css';
import './css/CustomDropdownSearchParmsDark.css';
import './css/EmojiPicker.css';
import './css/SearchEraser.css';
import './css/PostReturner.css';

import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import StartLogin from './components/StartLogin';
import Login from './components/login';
import AiConcierge from './components/AiConcierge';
import ForgotPassword from './components/ForgotPassword';
import EnterCode from './components/EnterCode';
import ResetPassword from './components/ResetPassword';
import Signup from './components/signup';
import Home from './pages/Home';
import ProfileOfLoginUser from './pages/ProfileOfLoginUser';
import ProfileOfAnyUser from './pages/ProfileOfAnyUser';
import Access from './components/UserAccess';
import Account from './pages/Account';
import Preferences from './pages/Preferences';
import Contactus from './pages/Contactus';
import DeleteAccount from './components/DeleteAccount';
import GetComments from './components/getData/GetComments';
import GetImages from './components/getData/GetImages';
import GetImagesOfLoginUser from './components/getData/GetImagesOfLoginUser';
import GetCollections from './components/getData/GetCollections';
import GetThemes from './components/getData/GetThemes';
import EditUserPicture from './components/EditUserPicture';
import TestEditUserPicture from './components/TestEditUserPicture';
import Signout from './components/Signout';
import Birdy from './components/birdy';
import Cat from './components/cat';
import UniversalSearch from './components/UniversalSearch';
import GetMenu from './components/getData/GetMenu';
import SharedPost from './pages/SharedPost';
import AppUrlListener from './utils/AppUrlListener';
import { setSession } from './utils/SessionUtils.js';
import { getAppWasAlreadyInvoked, setAppWasAlreadyInvoked } from './utils/CapacitorUtils.js';

export default function App() {
	if (getAppWasAlreadyInvoked()) {
		return null;
	}
	else {
		setAppWasAlreadyInvoked(true);
		setSession();
		return (
			<Router>
				<AppUrlListener></AppUrlListener>
				<div className="App"> 
						<Switch>
							<Route path="/" exact component={StartLogin} />
							<Route path="/navbar" component={Navbar} />
							<Route path="/login" component={Login} />
							<Route path="/aiconcierge" component={AiConcierge} />
							<Route path="/ForgotPassword" component={ForgotPassword} />
							<Route path="/EnterCode" component={EnterCode} />
							<Route path="/ResetPassword" component={ResetPassword} />
							<Route path="/signup" component={Signup } />
							<Route path="/Profile" component={ProfileOfLoginUser} />
							<Route path="/User" render={(props) => <ProfileOfAnyUser {...props} key={Date.now()}/>} />
							<Route path="/access/:name" render={(props) => <Access {...props} key={Date.now()}/>} />
							<Route path="/access/" component={StartLogin} />
							<Route path="/Account" component={Account} />
							<Route path="/Preferences" component={Preferences} />
							<Route path="/Contactus" component={Contactus} />
							<Route path="/Deleteaccount" component={DeleteAccount} />
							<Route path="/Home" component={Home} />
							<Route path="/GetCollections" component={GetCollections} />
							<Route path="/GetThemes" component={GetThemes} />
							<Route path="/Comments" component={GetComments} />
							<Route path="/GetImages" component={GetImages} />
							<Route path="/GetImagesOfLoginUser" component={ GetImagesOfLoginUser} />         
							<Route path="/EditUserPicture" component={EditUserPicture} />
							<Route path="/TestEditUserPicture" component={TestEditUserPicture} />
							<Route path="/signout" component={Signout} />
							<Route path="/birdy" component={Birdy} />
							<Route path="/cat" component={Cat} />
							<Route path="/Search" component={UniversalSearch} />
							<Route path="/GetMenu" component={GetMenu} />
							<Route path="/Post/:id" render={(props) => <SharedPost {...props} key={Date.now()}/>} />
							<Route path="/Post" component={StartLogin} />
						</Switch>
				</div>
			</Router> 
		);
	}
	
}