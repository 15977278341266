import  { isAndroidPlatform, isDesktop } from '../utils/AppUtils.js';

export function setOverflow(that) {
  let overflowX = 'hidden';
  let overflowY;
 
  if (isDesktop()) {
    overflowY = 'hidden';
  }
  else {
    overflowY = 'scroll';
  }
  that.setState({ styleObj: { overflowX: overflowX, overflowY: overflowY } });
}

export function setWrapperHeight(that) {
  let wrapper = that.wrapperRef.current;
  let rect;
  let exactHeight;

  if (wrapper) {
    rect = wrapper.getBoundingClientRect();
    exactHeight = rect.height;
    that.setState({ wrapperHeight: exactHeight })
  }
}

export function onFocusHandler(that) {
  let styleObj;
 
  if (isAndroidPlatform()) {
    styleObj = that.state.styleObj;
    styleObj.height = that.state.wrapperHeight + 'px';
    that.setState({ styleObj: styleObj });
  }
}

export function onBlurHandler(that) {
}