import GetYourPostTablet from './GetYourPostTablet';
import * as MdIcons from 'react-icons/md';
import { pageScrollToTop } from '../ScrollUtils.js';
import { isiPad } from '../../utils/AppUtils';

export default class GetYourPostTabletLandscape extends GetYourPostTablet {
	constructor(props) {
		super(props);
		this.state.isKeyboardUp = false;
		this.onFocusHandler = this.onFocusHandler.bind(this);
		this.onBlurHandler = this.onBlurHandler.bind(this);
		this.SMALL_TIMEOUT = 300;
	}

	componentDidUpdate() {
		// This is needed because of transition from AI input screen with keyboard to main AI screen without it.
		// When  disableAIinput() is called in the begining of AI request we can still be in AI input screen, where 
		// not all main AI screen elements are available, so here we disable them when we are in main AI screen.
		if (!this.state.submitToAINotSentYet) {
			this.disableAIinput(true, false);
		}
	}

	onFocusHandler = () => {
		let	stateObj = {
				isKeyboardUp : true, 
				backgroundImageClass : this.BACKGROUND_IMAGE_CLASS_NO_IMAGE,
				showHeaderArea : false,
				showFaces : false
			};
		this.setState(stateObj);
		if (isiPad()) {
			setTimeout(() => {
				// fixing iPad effect of keyboard pushing the input feld up and ouside of view port
				pageScrollToTop();
			}, this.SMALL_TIMEOUT);
		}
	}

	onBlurHandler = async () =>  {  
		setTimeout(() => {
			let	stateObj = {
				isKeyboardUp : false, 
				backgroundImageClass : this.BACKGROUND_IMAGE_CLASS,
				showHeaderArea : true,
				showFaces : true
			};
			
			this.setState(stateObj);
		}, this.TIMEOUT);
	}

	getProfileInfo(disabledAI, AIrequestOption, visibilityHidden) {
		if (!this.state.isKeyboardUp)  {
		  return this.getProfileInfoDiv(disabledAI, AIrequestOption, visibilityHidden);
		}
	}

	getRadioButtons(disabledAI, AIrequestOption) {
		if (!this.state.isKeyboardUp) {
		  return this.getRadioButtonsColumn(disabledAI, AIrequestOption);
		}
	}

	getPostReturnArrow() {
		if (this.state.isKeyboardUp) {
			return (
				<div className="postReturner" onClick={this.returnToFullScreenAI}>
					<MdIcons.MdArrowBack className = "postReturner-img" />
				</div>
			)
		}
		else {
			return null;
		}
	}

	returnToFullScreenAI(e) {
		// thic click handler does not do anything, the click is just causing blur event
		e.preventDefault();
	}
}