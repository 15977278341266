import React from "react";
import { MDBContainer, MDBModal, MDBModalBody } from 'mdbreact';
import DefaultUserPicture from '../images/default_user.jpg';
import Envir from '../components/Envir';
import Const from '../components/Const';
import * as MdIcons from 'react-icons/md';
import fakeDBresponse from '../components/data/DataShortLoginUserProd.js';
import Resizer from "react-image-file-resizer";
import { setPostImagesHeight, setPostImagesHeights, isPageScrollable,  messageModalPopUpScrollProcessor, SEARCH,
       } from './ScrollUtils.js';
import { setIOSCookie, getIOSCookie, deleteIOSCookie, SECOND, MINUTE } from '../utils/CapacitorUtils';
import { getClientId, getClientVersion } from '../utils/SessionUtils.js';
import { storeState, getState } from '../utils/StateStoreUtils.js';
import  { getFontSizeRange, setCssVarValue } from '../utils/CssUtils.js';
import Tippy from '@tippyjs/react';
import { roundArrow } from 'tippy.js';

const SCORE_OF_REQUIRED_CHARS = 30; // Upper case, lowercase, number and special char
const MIN_PASSWORD_LENGTH = 7;
const STRONG_PASSWORD_SCORE_TRESHOLD = 80;
const GOOD_PASSWORD_SCORE_TRESHOLD = 60;

const MIN_USER_NAME_LENGTH = 6;
const MAX_USER_NAME_LENGTH = 30;
const MAX_EMAIL_LENGTH = 96; // 254 in theory
const ENCODED_BRACKET = '%7B%'; // this is encoded '{'

let componentClass;
let errorMessageObj = {
  header : '',
  text : ''
}
let sharedPostFlag = false; 

//Post image
const POST_IMG_MAX_WIDTH_LENDSCAPE = 617;
const POST_IMG_MAX_HEIGHT_PORTRAIT = 460;
const POST_IMG_QUALITY = 100;
//user picture
const PIC_IMG_MAX_HEIGHT_PORTRAIT = 100; // for user profile landscape picture
const PIC_IMG_MAX_WIDTH_PORTRAIT = 100;  // for user profile portrait picture
const PIC_IMG_QUALITY = 85;
//
const IMAGE_TYPE = 'JPEG';
const ROOT_FONT_SIZE = 16;

export async function invokeService(serviceUrl, data, that) {
  let response;
  let parms = {};
  let serviceResult = null;
  let serviceError = null;
  let clientId = getClientId();
 
  if (Const.ACE_PRODUCT) {
    data["TN"] = Const.ACE_TN;
  }
  else {
    data["TN"] = Const.OCA_TN;
    if (serviceUrl === Const.OCA_SERVICES_URL) {
      data.ver = getClientVersion();
      data.client_id = (data.mode === Const.STORE_HASH_TAGS) 
                        ? clientId + Const.STORE_HASH_TAGS // invoke without wait: need to avoid CONCURRENT_LOCK 
                        : clientId;
    }
  }
  Object.assign(parms, Const.INVOKE_SERVICE_PARMS);
  await setAuthorizationHeader(parms);
  await that.setState({
    serviceInvocationStatus :  Const.SERVICE_IS_BEIGNG_INVOKED
  });
  parms.body = JSON.stringify(data);
  await fetch(serviceUrl, parms)
    .then(res => res.json())
    .then(
      (result) => {
        serviceResult = result;
        serviceResultLogic(result, that);
      },
      (err) => {
        serviceError = err;
        serviceErrorLogic(err, that);
      }
  )
  response = {
    result : serviceResult,
    error : serviceError
  }
  return response;
}

export async function serviceResultLogic(result, that) {
  let errorLevel;
  let stateObj;

  if (result.statusCode === Const.OK) {
     errorLevel = Const.NO_ERROR_LEVEL;
  }
  else if (result.statusCode < Const.USER_ERROR_MAX_VALUE) {
     errorLevel = Const.USER_ERROR_LEVEL;
     if (result.statusCode === Const.OLD_ACCESS_TOKEN_IN_DB_ERR) {
      // somebody is using stolen access token 
      deleteLocalCookie(Const.DELETE_ALL_COOKIES);
     }
     if (result.statusCode === Const.REQUEST_NEW_CLIENT_VERSION) {
      await setRefreshYourBrowserMessage(that);
     }
     else {
      result.statusCode = userErrorsMapping(result.statusCode);
      await setCloseYourBrowserMessage(that);
     }
  }
  else if ((result.statusCode > Const.USER_ERROR_MAX_VALUE) && (result.statusCode < Const.SYS_ERROR_MIN_VALUE)) {
     errorLevel = Const.USER_ERROR_CRITICAL_LEVEL;
     await setContactUsMessageCrytical(that);
  }
  else if ((result.statusCode ===  Const.SYS_ERROR_STORING_USER_LIKE_NO_POST_FOUND) ||
           (result.statusCode ===  Const.SYS_ERROR_DELETING_USER_LIKE_NO_POST_FOUND) ||
           (result.statusCode ===  Const.SYS_ERROR_STORING_USER_COMMENT_NO_POST_FOUND) ||
           (result.statusCode ===  Const.SYS_ERROR_UPDATING_USER_POST_NO_POST_FOUND) ||
           (result.statusCode ===  Const.SYS_ERROR_POST_COMMENTS_REFRESHING_NO_POST_FOUND) ||
           (result.statusCode ===  Const.SYS_ERROR_POST_COMMENTS_REFRESHING_NO_POST_FOUND) ||
           (result.statusCode ===  Const.SYS_ERROR_DELETING_USER_POST_NO_POST_FOUND)  // user deleted post from other device
                                                                                      // don't do anything : he deserves it 
          ) {
     errorLevel = Const.SYS_ERROR_LEVEL;
     await setAsynPostDeletionMessage(that);
  }
  else if ((result.statusCode ===  Const.SYS_ERROR_FOLLOWING_USER_NO_USER_FOUND) ||
           (result.statusCode ===  Const.SYS_ERROR_STORING_USER_POST_NO_USER_FOUND) // user deleted himself from other device
                                                                                    // don't do anything : he deserves it 
          ) {
     errorLevel = Const.SYS_ERROR_LEVEL;
     await setAsynUserDeletionMessage(that);
  }
  else {
     errorLevel = Const.SYS_ERROR_LEVEL;
     await setSystemErrorMessage(that);
  }
  stateObj = {
    serviceInvocationStatus :  Const.SERVICE_CALL_IS_COMPLETE,
    serviceStatusCode : result.statusCode,
    serviceErrorLevel : errorLevel,
    header : errorMessageObj.header, 
    text : errorMessageObj.text
  }
  if (result.flag !== undefined) {
    stateObj.flag = result.flag
  }
  that.setState(stateObj);
  if (result.hasOwnProperty('edt')) {
    result.edt = adjustEDTfromServer(result.edt);
  }
  if (result.token) {
    setLocalCookieForToken(result.token, result.edt);
  }
}

export async function serviceErrorLogic(err, that) {
  console.log(err);
  let stateObj;

  await setAppIsDownMessage(that);
  stateObj = {
    serviceInvocationError : true,
    serviceInvocationStatus :  Const.SERVICE_CALL_IS_COMPLETE,
    header : errorMessageObj.header, 
    text : errorMessageObj.text
  }
  that.setState(stateObj);
}

export function userErrorsMapping(statusCode) {
  // We do this mapping because the message for the below errors should be the same that we do for 
  // Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT, which is already processed in the code
  let mappedStatusCode = statusCode;

  if ((statusCode === Const.OLD_ACCESS_TOKEN_IN_DB_ERR) || (statusCode === Const.NO_COOKIE_OR_TOKEN)) {
    mappedStatusCode = Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT;
  }
  return mappedStatusCode;
}

export function isLoginUserName(loginName) {
  let atIndex = loginName.lastIndexOf('@');
  let itsUserName;

  if ((atIndex === -1) || (atIndex === 0)) {
      // Allow only one @ at the begining
      itsUserName = true;
  }
  else {
      itsUserName = false;
  }
  return itsUserName;
}

export function validateUserName(userName) {
  let isGoodUserName;
  let lastCharIsAdot = (userName[userName.length - 1] === '.');
  let hasAspecialChars = /[&=_'\\\-+,<>]/.test(userName);
  let hasAtLeastTwoDotsInArow = /(\.){2,}/.test(userName);
  let doesNotStartWithAdot = /^[^.]/.test(userName);
  let atIndex = userName.lastIndexOf('@');

  if ((userName.length < MIN_USER_NAME_LENGTH) || (userName.length > MAX_USER_NAME_LENGTH) ||
      lastCharIsAdot || hasAspecialChars || hasAtLeastTwoDotsInArow || !doesNotStartWithAdot ) {
      isGoodUserName = false;
  }
  else if ((atIndex !== -1) && (atIndex !== 0)) {
      // Allow only one @ at the begining
      isGoodUserName = false;
  }
  else {
      isGoodUserName = true;
  }
  return isGoodUserName;
}

export function validateEmail(email) {
  let lastAtPos = email.lastIndexOf('@');
  let twoAtsInArowPos = email.indexOf('@@');
  let lastDotPos = email.lastIndexOf('.');
  let firstCfharIsAdot = (email[0] === '.');
  let isEmailValid;

  if ((email.length > MAX_EMAIL_LENGTH) || (lastAtPos === -1) || (lastDotPos === -1) || (firstCfharIsAdot) || (twoAtsInArowPos > 0) || 
      (lastDotPos > email.length - 3) || (lastAtPos > lastDotPos)) {
         isEmailValid = false;
  }
  else {
         isEmailValid = true;
  }
  return isEmailValid;
}

export function validatePasswordStrength(password) {
  let passwordStrength;

  if (password.length < MIN_PASSWORD_LENGTH) {
      passwordStrength = Const.WEAK;
  }
  else {
      passwordStrength = checkPassStrength(scorePassword(password));
  }
  return passwordStrength;
}

export function scoreRequiredChars(pass) {
  let score = 0;
  let variations;
  let variationCount = 0;
  let variationWeight;
      
  // must have points for mixing it up
  variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass) // special chars
  };
  for (let ind in variations) {
      if (variations[ind]) {
          variationWeight = 1;
      }
      else {
          variationWeight = 0;
      }
      variationCount += variationWeight;
  }
  score += (variationCount - 1) * 10;
  return score;
}

export function scoreUniqueChars(pass) {
  let score = 0;
  let key;
  let value; // number of repetions;
  let letters = {};

  // award every unique letter 
  for (let i = 0; i < pass.length; i++) {
      key = pass[i];
      value = letters[key];
      if (!value) {
          value = 1;
          score += 5;
      }
      else {
          value++;
      }
      letters[key] = value;
  }
  return score;
}

export function scorePassword(pass) {
  let score = scoreRequiredChars(pass);
      
  if (score === SCORE_OF_REQUIRED_CHARS) {
    score += scoreUniqueChars(pass);
  }
  return score;
}

export function checkPassStrength(score) {
  let passStrength;

  if (score > STRONG_PASSWORD_SCORE_TRESHOLD)
     passStrength = Const.STRONG;
  else if (score > GOOD_PASSWORD_SCORE_TRESHOLD)
     passStrength = Const.GOOD;
  else 
     passStrength =  Const.WEAK;

  return passStrength;
}

export function setLocalCookie(loginUser) {
  let cookieKey = getLocalCookieKeyName();
  let cookieValue = loginUser.userFullName;
  let expiryDateString = "expires=" + (numberDateToDate(loginUser.cookieExpiryDate)).toUTCString();
  
  if (Const.ACE_PRODUCT) {
    createLocalCookie(cookieKey, cookieValue, expiryDateString, 0);
  }
}

export function setLocalCookieForToken(token, edt) {
  let cookieKey = getLocalTokenCookieKeyName();
  let cookieValue = token;
  let expiryDateString = "";

  if (edt > 0) {
    expiryDateString = "expires=" + (numberDateToDate(edt)).toUTCString();
  }
  createLocalCookie(cookieKey, cookieValue, expiryDateString, edt);
}

export function setLocalCookieForOcaUser(userName, edt) {
  let cookieKey = getLocalCookieKeyName(); 
  let cookieValue = userName;
  let expiryDateString = "";

  if (edt > 0) {
    expiryDateString = "expires=" + (numberDateToDate(edt)).toUTCString();
  }
  createLocalCookie(cookieKey, cookieValue, expiryDateString, edt);
}

export function setLocalCookieForOcaAnyUser(obj) {
  let cookieKey = getAnyUserCookieKeyName(); 
  let cookieValue = JSON.stringify(obj);
  let expiryDateString = "";

  createLocalCookie(cookieKey, cookieValue, expiryDateString, 0);
}

export function setLocalCookieForOcaPreferences(theme) {
  let cookieKey = gePreferencesKeyName(); 
  let cookieValue = theme;
  let expiryDateString = "";

  createLocalCookie(cookieKey, cookieValue, expiryDateString, 0);
}

export function setLocalCookieForOcaMkt(obj) {
  let cookieKey = getMktCookieKeyName(); 
  let cookieValue = JSON.stringify(obj);
  let expiryDateString = "";

  createLocalCookie(cookieKey, cookieValue, expiryDateString, 0);
}

export async function getLocalCookieForOcaAnyUser() {
  // any user cookie value is an object like {  name: "minimalista", key: "ak7zu0" }
  let cookieKey = getAnyUserCookieKeyName();
	let valueObj  = await getAppCookie(cookieKey);
  
  if (valueObj) {
    if (valueObj.indexOf(ENCODED_BRACKET) === 0) {
       // this check is needed because old any user cookie had only user name
       valueObj = decodeURIComponent(valueObj);
       valueObj = JSON.parse(valueObj);
    }
  }
  return valueObj;
}

export async function getLocalCookieForOcaPreferences() {
  let cookieKey = gePreferencesKeyName();
	let value = await getAppCookie(cookieKey);

  if (value !== "" && (typeof value === 'string') ) {
      value = parseInt(value, 10); // '1' -> 1
  }
  return value;
}

export function getLocalCookieForOcaPreferencesSync() {
  // sync way of getting preference cookie for non-iOS App
  // to avoid screen ficker - iOS does it via AppListener
  let cookieKey = gePreferencesKeyName();
	let value = getRegularAppCookie(cookieKey);
  
  if (value !== "" && (typeof value === 'string') ) {
      value = parseInt(value, 10); // '1' -> 1
  }
  return value;
}

export async function getLocalCookieForOcaMkt() {
  // any market place cookie value is an object like {  customer_id: "WLkfXgh2xXf", pricing_dimension: "1" / "0"}
  let cookieKey = getMktCookieKeyName();
 	let valueObj = await getAppCookie(cookieKey);
   
  if (valueObj) {
    valueObj = decodeURIComponent(valueObj);
    valueObj = JSON.parse(valueObj);
  }
  return valueObj;
}

export function setLocalCookieForOcaComments(obj) {
  // session cookie needed when refreshing browser for comment
  let cookieKey = getCommentsCookieKeyName(); 
  let cookieValue = JSON.stringify(obj);
  let expiryDateString = "";

  createLocalCookie(cookieKey, cookieValue, expiryDateString, 0);
}

export function createLocalCookie(cookieKey, cookieValue, expiryDateString, edt) {
  if (Envir.ANDROID_IOS_APP) {
    // call to async function awaiting itself inside normal function
    (async function() {
      await setIOSCookie(cookieKey, cookieValue, edt);
    })();
  }
  else {
    createRegularLocalCookie(cookieKey, cookieValue, expiryDateString);
  }
}

export function createRegularLocalCookie(cookieKey, cookieValue, expiryDateString) {
  let cookieDetails = "; Path=/;";  
  
  cookieKey = cookieKey + "=";
  cookieValue = encodeURIComponent(cookieValue);
  cookieValue = Buffer.from(cookieValue, 'binary').toString('base64');
  document.cookie = cookieKey + cookieValue + "; " + expiryDateString + cookieDetails;
}

export function getLocalCookieKeyName() {
  let cookieKey;
  
  if (Const.ACE_PRODUCT) {
    cookieKey = Const.ACE_COOKIE_KEY;
  }
  else {
    cookieKey = Const.OCA_COOKIE_KEY;
  }
  return cookieKey;
}

export function getAnyUserCookieKeyName() {
  return Const.OCA_ANY_USER_COOKIE_KEY;
}

export function getMktCookieKeyName() {
  return Const.OCA_MKT_COOKIE_KEY;
}

export function gePreferencesKeyName() {
  return Const.OCA_PREFERECE_COOKIE_KEY;
}

export function getCommentsCookieKeyName() {
  return Const.OCA_COMMENTS_COOKIE_KEY; 
}

export function getLocalTokenCookieKeyName() {
  let tokenCookieKey;

  if (Const.ACE_PRODUCT) {
    tokenCookieKey = Const.ACE_TOKEN_COOKIE_KEY;
  }
  else {
    tokenCookieKey = Const.OCA_TOKEN_COOKIE_KEY;
  }

  return tokenCookieKey;
}

export function getProductPrefix() {
  let prefix;
  
  if (Const.ACE_PRODUCT) {
    prefix =  Const.ACE_COOKIE_KEY.substring(0, 1);
  }
  else {
    prefix =  Const.OCA_COOKIE_KEY.substring(0, 1);
  }
  return prefix;
}

export async function getLocalTokenCookie(cookieName) {
  return await getAppCookie(cookieName);
}

export async function getAppCookie(cookieName) {
  let cookieValue;

  if (Envir.ANDROID_IOS_APP) {
    cookieValue = await getIOSCookie(cookieName);
  }
  else {
    cookieValue = getRegularAppCookie(cookieName);
  }
  return cookieValue;
}

export function getRegularAppCookie(cookieName) {
  let localCookieValue;
  let decodedCookie;
  let localCookieArr;
  let localCookie;
  let decodeCookieValue = "";

  if (document.cookie) {
    decodedCookie = decodeURIComponent(document.cookie);
    localCookieArr = decodedCookie.split(';');
    for(let i = 0; i < localCookieArr.length; i++) {
      localCookie = localCookieArr[i].trim();
      if (localCookie.substring(0, cookieName.length) === cookieName) {
        localCookieValue = localCookie.substring(cookieName.length + 1);
        decodeCookieValue = Buffer.from(localCookieValue, 'base64').toString('binary'); // atob() replacement
        break;
      }
    }
  }
  return decodeCookieValue;
}
//DELETE_ANY_USER_COOKIE
export function deleteLocalCookie(option) {
  let cookieDetails = "=; Max-Age=-1; expires=Thu, 01 Jan 1970 00:00:00 GMT; Path=/;";
  let cookieKey;

  switch (option) {
      case Const.DELETE_ACCOUNT_COOKIE:
      default:
                                        cookieKey = getLocalCookieKeyName();
                                        break;
      case Const.DELETE_TOKEN_COOKIE:
                                        cookieKey = getLocalTokenCookieKeyName();
                                        break;
      case Const.DELETE_COMMENTS_COOKIE:
                                        cookieKey = getCommentsCookieKeyName();
                                        break;
      case Const.DELETE_ANY_USER_COOKIE:
                                        cookieKey = getAnyUserCookieKeyName();
                                        break;
      case Const.DELETE_PREFERENCES_COOKIE:
                                        cookieKey = gePreferencesKeyName();
                                        break;
      case Const.DELETE_MKT_COOKIE:
                                        cookieKey = getMktCookieKeyName();
                                        break;
      case Const.DELETE_ALL_COOKIES:
                                        cookieKey = getLocalCookieKeyName();
                                        document.cookie = cookieKey + cookieDetails;
                                        cookieKey = getLocalTokenCookieKeyName();
                                        break;
  }
  if (Envir.ANDROID_IOS_APP) {
    // call to async function awaiting itself inside normal function
    (async function() {
      await deleteIOSCookie(cookieKey);
    })();
  }
  else {
    document.cookie = cookieKey + cookieDetails;
  }
}

export function deleteAllLocalCookies() {
  deleteAllButTokenLocalCookies();
  deleteLocalCookie(Const.DELETE_TOKEN_COOKIE);
}

export function deleteAllButTokenLocalCookies() {
  deleteLocalCookie(Const.DELETE_ACCOUNT_COOKIE);  
  deleteLocalCookie(Const.DELETE_COMMENTS_COOKIE);
  deleteLocalCookie(Const.DELETE_ANY_USER_COOKIE);
  deleteLocalCookie(Const.DELETE_PREFERENCES_COOKIE);
}

export function browserDetect(nua) {
                 
  let userAgent = nua;
  let browserName;
  
  if(userAgent.match(/edg/i)) {
      browserName = 'E'; //"edge";
  }
  else if(userAgent.match(/opr\//i)){
      browserName = 'O'; //"opera";
  }
  else if(userAgent.match(/firefox|fxios/i)){
      browserName = 'F'; //"firefox";
  } 
  else if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = 'C'; //"chrome";
  }
  else if(userAgent.match(/safari/i)){
      browserName = 'S'; //"safari";
  }else{
      browserName = 'N'; //"No browser detection";
  }
  return browserName;      
}

export function dateToNumberDate(date) {
  let numberDate = parseInt(Number(date) / 1000, 10);

  return numberDate;
}

export function numberDateToDate(numberDate) {
  let date = new Date(numberDate * 1000);

  return date;
}

export async function setAuthorizationHeader(parms) {
  let tokenCookieName =  getLocalTokenCookieKeyName();
  let token = await getLocalTokenCookie(tokenCookieName); 

  if (token !== "") {
    token = ' ' + token;
  }
  parms.headers.Authorization = "Bearer" + token;
}

export function setWindowLocation() {
  if (Const.ACE_PRODUCT) {
    window.location = Envir.LOGIN_FOLDER + 'orders';
  }
  else {
    window.location = Envir.LOGIN_FOLDER + 'home';
  }
}
export function extractImageParts(imagePreviewUrl) {
  let imageArray1 = imagePreviewUrl.split(';');
  let imageType = imageArray1[0].substring(11);
  let imageArray2 = imageArray1[1].split(',');
  let imageBody = imageArray2[1];
  let retObj = {
      image_type : imageType,
      image_body : imageBody
  }

  return retObj;
}

export function getS3bucketItem(that, url, statePropertyName) {
  let data;
  let xhr = new XMLHttpRequest();

  xhr.that = that;
  xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        data = JSON.parse(xhr.response);
        assembleJson(that, statePropertyName, data);
        console.log(data);
      }
  };
  xhr.open('GET', url);
  xhr.send();
}

export function assembleJson(that, statePropertyName, data) {
  let user = {};
  let count = that.state.propertyCount + 1;

  Object.assign(user, data);
  if (statePropertyName === 'bio') {
    that.setState({[statePropertyName]: data});
  }
  else {
    that.setState({[statePropertyName]: data[statePropertyName]});
  }
  that.setState({propertyCount: count});
  
  if (count === 1) {
    Object.assign(user, data);
  }
  if (count === 2) {
    Object.assign(user, data, that.state.bio);
    // user = that.state.bio;
  }
  if (count === 3) {
    Object.assign(user, data, that.state.bio, );
    // user.userPosts = that.state.bio;
    user.whoIsUserFollowing = that.state.whoIsUserFollowing;
    that.setState({loginUser: user});
    
  }
}

export async function getUserJsonFromDBwithCookie(that) {
  let cookieKey = getLocalCookieKeyName();
	let userName = await getAppCookie(cookieKey);
  let ret;

  if (userName) {
    ret = await getUserJsonFromDB(that, userName);
  }
  else {
    window.location = '/';
  }
  
  return ret;
}

export async function getUserJsonFromDB(that, userName) {
  let data = null;
  let ret;

  if (userName) {
    data = {
      user_name : userName
    };
  }
  ret = await getUserJson(that, data);
  return ret;
}

export async function getUserJson(that, paramData) { 
  let ret;
  let response;
  let data = {};
  
  if (paramData) {
    data = paramData;
  }
  data["mode"] = Const.GET_USER_JSON_MODE;
  if (Envir.DB_JSON === 'ON') {
    response = await invokeService(Const.OCA_SERVICES_URL, data, that);
    ret = await adjustJsonPicturesAndTime(response.result);
    processFontSizeOfLoginUser(ret);
  }
  else {
    ret = fakeDBresponse;
  }
  return ret;
}

export function processFontSizeOfLoginUser(loginUser) {
  let fontSize;
  
  if (loginUser) {
    fontSize = loginUser.hasOwnProperty('fontSize') ? loginUser.fontSize : 0;
    if ((fontSize !== 0) && isPhoneLayout()) {
        setCssVarValue('--koeff', koeffCalculator(adjustFontSize(fontSize)));
    }
  }
}

export function adjustFontSize(fontSize) {
  // one user can have both iPhone and android phone 
	let adjustedFontSize = fontSize;
  let fontSizeRange = getFontSizeRange();
	let MAX_FONT_SIZE = fontSizeRange.maxFontSize;
	let MIN_FONT_SIZE = fontSizeRange.minFontSize;

  // here we do the best we can to avoid limits breaks for a different phone OS
  if (adjustedFontSize > MAX_FONT_SIZE) {
    adjustedFontSize = MAX_FONT_SIZE;
  }
  if (adjustedFontSize < MIN_FONT_SIZE) {
    adjustedFontSize = MIN_FONT_SIZE;
  }
	return adjustedFontSize;
}

export function koeffCalculator(fontSize) {
	let koeff = fontSize / ROOT_FONT_SIZE;

	return koeff;
}

export async function loadPartialJsonPosts(that, paramData) { 
  let data = {};
  let response;
  let ret;

  if (paramData) {
    data = paramData;
  }
  data["mode"] = Const.GET_PARTIAL_USER_JSON;
  if (Envir.DB_JSON === 'ON') {
    response = await invokeService(Const.OCA_SERVICES_URL, data, that);
    ret = await adjustJsonPicturesAndTime(response.result);
  }
  return ret;
}

export  function initUserBioProfile(userName) {
  let loginBioUser ={
    userName : userName,
    userN : userName,
    userComplete : false,
    userId : Const.NON_EXISTANT_USER_ID,
    userBio : "",
    userWebsite : "",
    userTagline : "",
    // userPicture : "",
    userNumberOfPosts : 0,
    userNumberOfFollowers : 0,
    userNumberOfFollowing :0,
    showFollowig : true,
    userPictureNumericDate : 0,
    userPosts: [] 
  };

  return loginBioUser;
}

export  function addUserPicture(jsonElement, propertyArr) {
  if (jsonElement.hasOwnProperty(propertyArr)) {
    for (let propertyArrElement of jsonElement[propertyArr]) { 
      addUserPictureToRootElement(propertyArrElement); 
      if (propertyArrElement.hasOwnProperty('time')) {
        propertyArrElement.time = convertUTCDateToLocalDate(propertyArrElement.time);
      }
    }
  }
}

export  function addUserPictureToRootElement(rootJsonElement) {
  let userPictureProperty = 'userPicture';

  if (rootJsonElement.hasOwnProperty('userPictureNumericDate')) {
    if (rootJsonElement.userPictureNumericDate !== 0) {
      rootJsonElement[userPictureProperty] = Const.USER_PICTURE_URL + rootJsonElement.userName + '/' + rootJsonElement.userPictureNumericDate;
    }
    else {
      rootJsonElement[userPictureProperty] = DefaultUserPicture; //Const.DEFAULT_USER_PICTURE; 
    }
  }
}

export function addPostImage(post) {
  if ((post.withImage === '1') && (post.picNumDate !== 0)) {
    post.postImg = Const.USER_POST_URL + post.userName + '/' + post.picNumDate;
  }
}

export function calculateAIremainingPostsAndImages(userJson) {
  let dateObj;
  let month;
  let year;

  if (userJson.hasOwnProperty('yearOfAIrequests') && userJson.hasOwnProperty('monthOfAIrequests') &&
       userJson.hasOwnProperty('numberOfAIpostRequests') && userJson.hasOwnProperty('numberOfAIpostRequests')) {
        dateObj = new Date();
        month = dateObj.getMonth() + 1; //months from 1-12
        year = parseInt(dateObj.getFullYear().toString().substring(3), 10);
        if ((userJson.yearOfAIrequests !== year) || (userJson.monthOfAIrequests !== month)) {
          userJson.numberOfAIpostRequests = Const.AI_MOTHLY_POST_LIMIT;
          userJson.numberOfAIimageRequests = Const.AI_MOTHLY_IMIGE_LIMIT;
        }
        userJson.yearOfAIrequests = year;
        userJson.monthOfAIrequests = month;
  }
}

export async function adjustJsonPicturesAndTime(userJson) {
  if (userJson) {
    addUserPictureToRootElement(userJson);
    addUserPicture(userJson, 'whoIsUserFollowing');
    addUserPicture(userJson, 'followers');
    if (userJson.hasOwnProperty('topPosts')) {
      for (let post of userJson.topPosts) { 
        addUserPictureToRootElement(post);
        addUserPicture(post, 'comments'); 
        addUserPicture(post, 'likes'); 
        addPostImage(post);
        post.time = convertUTCDateToLocalDate(post.time);
        replaceUnderscoredWordsWithHashtags(post);
      }
      calculateAIremainingPostsAndImages(userJson);
      await setPostImagesHeights(userJson.topPosts);
    }
    if (userJson.hasOwnProperty('userPosts')) {
      for (let post of userJson.userPosts) { 
        post.userPictureNumericDate = userJson.userPictureNumericDate;
        addUserPictureToRootElement(post);
        addUserPicture(post, 'comments'); 
        addUserPicture(post, 'likes'); 
        addPostImage(post);
        post.time = convertUTCDateToLocalDate(post.time);
        post.userPictureType = userJson.userPictureType;
        replaceUnderscoredWordsWithHashtags(post);
      }
      await setPostImagesHeights(userJson.userPosts);
    }
    if (userJson.hasOwnProperty('onePost')) {
      let post = userJson.onePost;

      addUserPictureToRootElement(post);
      addUserPicture(post, 'comments'); 
      addUserPicture(post, 'likes');
      addPostImage(post);
      post.time = convertUTCDateToLocalDate(post.time);
      replaceUnderscoredWordsWithHashtags(post);
      await setPostImagesHeight(userJson.onePost);
    }
  }
  return userJson;
}

export function modalWindow(that, header, text) {
  	return 	(
					<MDBContainer>
							<MDBModal isOpen={that.state.modal} toggle={that.toggle}>
								<div className="modal-myheader" >
									<MdIcons.MdClose className="modal-close" onClick={that.toggle} />
									<h6> {header} </h6>
								</div>
								<MDBModalBody>            
									<p> {text} </p>
								</MDBModalBody> 
						</MDBModal>
					</MDBContainer>
	  			)
}

export function messageModalWindow(that, header, text) {
  return (
          that.state.messageModal &&
          <MDBContainer>
            <MDBModal isOpen={that.state.messageModal} className="message-modal" toggle={messageToggle} >
              <div className="modal-myheader" >
                <MdIcons.MdClose className="modal-close left pointer-view" onClick={messageToggle} />
                <h6>{header}</h6>
              </div>
              <MDBModalBody>            
                <p style = {{ whiteSpace: 'pre-wrap', lineHeight: '1.3' }}>{text}</p>
              </MDBModalBody> 
            </MDBModal>
          </MDBContainer>
  			) 
}

export async function setAIisDownMessage(that) {
  componentClass = that;
  
  that.setState({ header : "AI concierge is out", text :  "He will return soon!"})
  messageToggle();
}

export async function setAIErrorMessage(that) {
  componentClass = that;
  
  that.setState({ header : "AI concierge is not OK", text :  "He will get better soon!"})
  messageToggle();
}

export async function setSavePostInfoDownMessage(that) {
  componentClass = that;
  
  that.setState({ header : "Share Post Outage", text :  "The service will be back soon!"})
  messageToggle();
}

export async function setSavePostInfoErrorMessage(that) {
  componentClass = that;
  
  that.setState({ header : "Share Post Error", text :  "The service will be back soon!"})
  messageToggle();
}

export async function setAppIsDownMessage(that) {
  componentClass = that;
  errorMessageObj = {
    header : "Please check Internet connection",
    text :  "Cloud services we use are down."
  };
  messageToggle();
}

export async function setAwsIsDownMessage(that) {
  componentClass = that;
  errorMessageObj = {
    header : "Cloud services we use are down",
    text :  "We will be up running as soon as the outage is fixed!"
  };
  messageToggle();
}

export async function setSystemErrorMessage(that) {
  componentClass = that;
  errorMessageObj = {
    header : "System error occurred",
    text :  "Please close your browser and try again"
  };
  messageToggle();
}

export async function setCloseYourBrowserMessage(that) {
  componentClass = that;
  errorMessageObj = {
    header : "Oops! Something went wrong!",
    text :  "Please close your browser and try again"
  };
  messageToggle();
}

export async function setRefreshYourBrowserMessage(that) {
  componentClass = that;
  if (Envir.ANDROID_IOS_APP) {
    errorMessageObj = {
      header : "Old app version detected",
      text :  "Please update it for better experience"
    };
  }
  else {
    errorMessageObj = {
      header : "Browser refresh required",
      text :  "Please refresh your browser and try again"
    };
  }
  
  messageToggle();
}

export async function setContactUsMessageCrytical(that) {
  componentClass = that;
  errorMessageObj = {
    header : "Critical user error occured",
    text :  "To resolve it email us at\n" + Const.EMAIL
  };
  messageToggle();
}

export async function setAsynPostDeletionMessage(that) {
  componentClass = that;
  errorMessageObj = {
    header : "Oops! Cannot compplete this action",
    text : "Looks like this post was recently deleted by its owner."
  };
  messageToggle();
}

export async function setAsynUserDeletionMessage(that) {
  componentClass = that;
  errorMessageObj = {
    header : "Oops! Cannot compplete this action",
    text : "Looks like this user was recently deleted and no longer with us."
  };
  messageToggle();
}

export function messageToggle() {
  messageModalPopUpScrollProcessor(componentClass);
  componentClass.setState({
    messageModal: !componentClass.state.messageModal
  });
}

export function setErrorMessageObject(header, text) {
  errorMessageObj = {
    header : '',
    text : '',
    messageModal : false
  }
}

export function resizeImage(file, that, isPostImage) {
  let imgQuality = isPostImage ? POST_IMG_QUALITY : PIC_IMG_QUALITY;
  
  console.log(that.imageParams.maxWidth + '    ' +   that.imageParams.maxHeight);
  try {
      Resizer.imageFileResizer(
        file,
        that.imageParams.maxWidth,
        that.imageParams.maxHeight,
        IMAGE_TYPE, 
        imgQuality,
        0,
        (uri) => {
          afterResizingSuccesProcessing(that, file, uri, isPostImage);
        },
        "base64",
        that.imageParams.maxWidth,
        that.imageParams.maxHeight,
      );
    } catch (err) {
        afterResizingFailureProcessing(that, err);
    }
}

export function afterResizingSuccesProcessing(that, file, uri, isPostImage) {
  that.setState({ file: file, imagePreviewUrl: uri });
  if (isPostImage) {
      that.parent.setState({ imageIsNotUploaded : false });
  }
  else {
    that.onPictureUploadCompletion(true, that);
  }
} 

export function afterResizingFailureProcessing(that, err) {
  that.onPictureUploadCompletion(false, that);
  componentClass = that;
  that.setState({
    messageModal : true,
    header : Const.IMAGE_ERROR_HEADER,
    text : Const.UNABLE_TO_PROCESS_THE_IMAGE
  });
  console.log(err);
} 

export function readLocalImageFile(event, that, isPostImage) {
  var fileTypes = Const.ACCEPTED_FILE_TYPES;
  let file;
  
  event.preventDefault();
  file = event.target.files[0];
  if (fileTypes.indexOf(file.type) < 0) {
    componentClass = that;
    that.setState({
      messageModal : true,
      header : Const.IMAGE_ERROR_HEADER,
      text : Const.UNKNOWN_IMAGE_TYPE
    });
  }
  else if (file.size > Const.MAX_IMAGE_SIZE) {
    componentClass = that;
    that.setState({
      messageModal : true,
      header : Const.IMAGE_ERROR_HEADER,
      text : Const.IMAGE_SIZE_MAY_NOT_EXEED + parseInt(Const.MAX_IMAGE_SIZE / (1024 * 1000)) + "MB"
    });
  }
  else {
    getImageParams(file, that, isPostImage);
  }
}

export async function getImageParams(file, that, isPostImage) {
  let reader  = new FileReader();

  reader.onloadend = function(e) {
    let image  = new Image();
    let imageParams;

    image.onload = function() {
      imageParams =  {
        width : image.width,
        height : image.height,
      };
      that.imageParams = resizerParamsCalculator(imageParams, isPostImage);
      resizeImage(file, that, isPostImage);
    };
    image.src = e.target.result;
  };
  reader.readAsDataURL(file);
}

export function resizerParamsCalculator(imageParams, isPostImage) {
  let ret;
  let heightToWidthRatio = imageParams.height / imageParams.width;
  let widthToHeightRatio = imageParams.width / imageParams.height;
  let isLandscape = (heightToWidthRatio <= 1) ? true : false;

  if (isPostImage) {
    if (isLandscape) {
      ret = {
        maxWidth : POST_IMG_MAX_WIDTH_LENDSCAPE,
        maxHeight : POST_IMG_MAX_WIDTH_LENDSCAPE * heightToWidthRatio
      };
    }
    else {
      ret = {
        maxWidth : POST_IMG_MAX_HEIGHT_PORTRAIT * widthToHeightRatio,
        maxHeight : POST_IMG_MAX_HEIGHT_PORTRAIT
      };
    }
  }
  else {
    // user pic image : widthToHeightRatio will be correct for both landscape and portrait images
    if (isLandscape) {
      ret = {
        maxWidth : PIC_IMG_MAX_HEIGHT_PORTRAIT * widthToHeightRatio,
        maxHeight : PIC_IMG_MAX_HEIGHT_PORTRAIT
      };
    }
    else {
      ret = {
        maxWidth : PIC_IMG_MAX_WIDTH_PORTRAIT,
        maxHeight : PIC_IMG_MAX_WIDTH_PORTRAIT * heightToWidthRatio
      };
    }
    
  }
  return ret;
}

export function webSiteUrlCorrector(userWebsiteUrl) {
  let userWebsiteUrlCorrected = '';
  let http = 'http';
  let httpProtocol = http + '://';

  if (userWebsiteUrl) {
    if (userWebsiteUrl.indexOf(http) < 0) {
      userWebsiteUrlCorrected = httpProtocol + userWebsiteUrl;
    }
    else {
      userWebsiteUrlCorrected = userWebsiteUrl;
    }
  }
  return userWebsiteUrlCorrected;
}

export function sleepingCat() {
  return sleepingCatSmall(1);
}

export function sleepingCatSmall(option) {
  let styleObj = { 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto'
      }

  if (option === 1) {
    styleObj.height = '100vh'; // full screen fro big cat
  }
  return <React.Fragment>
          <div className="background" style={styleObj} >
            <div className="cat-container">
              <div className="cat">
              <div className="head">
                <div className="earl"></div>
                <div className="earr"></div>
                <div className="eyel"></div>
                <div className="eyer"></div>
                <div className="mouth"></div>
                <div className="m"></div>
                <div className="nose"></div>
              </div>
              <div className="tail"></div>
              <div className="cat-body">
                <div className="feetl"></div>
                <div className="feetr"></div>
              </div>
              </div>
              <div className="bubble"></div>
              <div className="shadow"></div>
            </div>
          </div>
        </React.Fragment>
    ;
}

export function crossBrowserDate(dateTimeStringUTC) {
  let ar = dateTimeStringUTC.split(' ');
  let yearMonthDay = ar[0].split('-');
  let hourMinSec = ar[1].split(':');
  let date = new Date(yearMonthDay[0], yearMonthDay[1] - 1, yearMonthDay[2], 
                      hourMinSec[0], hourMinSec[1], hourMinSec[2]);

  return date;
}

export function convertUTCDateToLocalDate(dateTimeStringUTC) {
  // '2022-03-10 22:02:44' this an example of dateTimeStringUTC from the server
  let date = crossBrowserDate(dateTimeStringUTC);
  let newDate = new Date(date.getTime() - date.getTimezoneOffset() * MINUTE);
  let options = { year: 'numeric', month: 'long', day: 'numeric' };

  return newDate.toLocaleDateString("en-US", options);   
}

export function adjustEDTfromServer(edt) {
  let date;
  let localTimeZoneOffset;
  let adjustEDT = 0;
  
  if (edt > 0) {
    date = new Date();
    localTimeZoneOffset = date.getTimezoneOffset();
    adjustEDT = edt * SECOND - (localTimeZoneOffset - Const.ESTERN_TIME_ZONE_OFFSET) * MINUTE;
    adjustEDT = parseInt(adjustEDT / SECOND, 10);
  }
  return adjustEDT;   
}

export function findMyPostsWithPictures(anyUserPosts) {
  let postWithPicturePresent = false;

  for (let anyUserPost of anyUserPosts) {
    if (anyUserPost.hasOwnProperty('postImg')) {
      postWithPicturePresent = true;
      break;
    }
  }
  return postWithPicturePresent;
}

export function personImayFollow(userName, userPicture) {
  let personImayFollow = {
    userName : userName,
    userPicture : userPicture,
    userNumberOfPosts : 0,
    userNumberOfFollowers : 0,
    userNumberOfFollowing :  0,
    userTagline : '',
    userBio : '',
    userWebsite : ''
  }

  return personImayFollow;
}

export function contactUs() {
  let myClassName;
  let blocker = false;

  if (isPageScrollable()) {
    myClassName = "contact-us";
  }
  else {
    myClassName = "contact-us-sticky";
  }
  if (blocker) {
    return (
      <div className={myClassName}> 
        <span className="">Powered by Crystal Prism Inc</span>
        <span className="">&nbsp;&nbsp;&nbsp;2022@Copyright</span>
      </div>   
    );
  }
  else {
    return null;
  }
}

export function contactUsWithEmail() {
  let myClassName;
  let blocker = false;

  if (isPageScrollable()) {
    myClassName = "contact-us";
  }
  else {
    myClassName = "contact-us-sticky";
  }
  if (blocker) {
    return (
      <div className={myClassName}>
            <span className="">Powered by Crystal Prism Inc</span>
						<span className="">&nbsp;&nbsp;&nbsp;2022@Copyright</span>
						<span className="pl-2"> customerSupport@crystalprismsoftware.com </span>							
					</div>   
    );
  }
  else {
    return null;
  }
}

export async function changeHandler(event, that) {
  let currentValue = event.target.value;
  
  changeHandlerNoEvent(currentValue, that);
}

export async function changeHandlerNoEvent(currentValue, that) {
  let previousValue = that.state.value;
  let charCounter = that.state.charCounter;
  let noPostText = false;
  let allowedLength = that.MAX_LENGTH + that.DELTA;
  let stateObj;

  if (currentValue.length > allowedLength) {
    // phone does not respect text area maxLength
    currentValue = currentValue.substring(0, allowedLength);
    charCounter = -that.DELTA;
  }
  else {
    if ((charCounter === that.MAX_LENGTH) && (previousValue)) {
      previousValue = ''; // this condition is useful for post update
    }
    if (!currentValue) {
      noPostText = true;
      charCounter = that.MAX_LENGTH;
    }
    else if (!previousValue) {
      charCounter = that.MAX_LENGTH - currentValue.length;
    }
    else if (currentValue.length > previousValue.length) {
      charCounter = charCounter - (currentValue.length - previousValue.length);
    }
    else {
      charCounter = charCounter + (previousValue.length - currentValue.length);
    }
  }
  stateObj = { 
               value: currentValue, 
               noPostText : noPostText, 
               charCounter : charCounter 
             };
  that.setState(stateObj); 
}

export function remainingChars(charCounter) {
  if (charCounter <= Const.SHOW_REMAINING_CHAR_NUM) {
    if (charCounter === 1) {
      return (<div className="counter-d green"><p id="counter" > +{charCounter} </p></div>)
    }
    else if (charCounter > 0) {
      return (<div className="counter-d green"><p id="counter" > +{charCounter} </p></div>)
    }
    else if (charCounter === 0) {
      return (<div className="counter-d green"><p id="counter" > {charCounter} </p></div>)
    }
    else {
      return (<div className="counter-d red"><p id="counter" > {charCounter} </p></div>)
    }
  }
}

export function setPostTextOverflow(charCounter, that) {
  if (charCounter >= 0) {
    that.textOverflow = false;
  }
  else {
    that.textOverflow = true;
  }
}

export function processAsyncPostDeletion(clikedPost, that) {
  let loginUser = that.state.loginUser;
  let anyUser = that.state.anyUser;

  deletePostInMemory(loginUser.topPosts, clikedPost)
  if (anyUser) {
    deletePostInMemory(anyUser.userPosts, clikedPost)
  }
}

export function deletePostInMemory(posts, clikedPost) {
  let ind = 0;

  for (let post of posts) {
    if (post.postId === clikedPost.postId) {
      posts.splice(ind, 1);
      break;
    }
    ind++;
  }
}

export function followingFollowersCondition(loginUser, anyUser, that) {
  let ocaUser = anyUser ? anyUser : loginUser;
  let cond1 = (that.state.hasOwnProperty('postView')) ? that.state.postView : true;
  let cond2 = loginUser.showFollowig && ocaUser.hasOwnProperty('whoIsUserFollowing') && (ocaUser.whoIsUserFollowing.length > 0);
  let cond3 = !loginUser.showFollowig && ocaUser.hasOwnProperty('followers') && (ocaUser.followers.length > 0);

  return cond1 && (cond2 || cond3);
}

export function getClassNameForWrapDiv(loginUser, anyUser, that) { 
  let className;

  if (followingFollowersCondition(loginUser, anyUser, that)) {
    // this class takes place when followers are present
      className = 'after-app-wrap-center-div';
  }
  else {
    // this class takes place when there are no followers
      className = 'after-app-wrap-center-div-gotop';
  }

  return className;
}

export function urlify(text) {
  let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  
  return text.replace(urlRegex, function(url, c) {
      let url2 = (c === 'www.') ?  'http://' + url : url;
      return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
  }) 
}

export function getUserPictureClassName(obj) {
  let avatarClassName = (obj.userPictureType === '0') ? 'avatar-small-landscape' : 'avatar-small-portrait';

  return avatarClassName;
}

export function getUserPictureForAIClassName(obj) {
  let avatarAIclassName = (obj.userPictureType === '0') ? 'avatar-ai-landscape' : 'avatar-ai-portrait';

  return avatarAIclassName;
}

export function replacePostInTopPosts(post, loginUser) {
  let ind = 0;
  let foundTopPost = false;
  let storedState;
  let hashTagPosts;

  if (loginUser.hasOwnProperty('topPosts')) {
    for (let topPost of loginUser.topPosts) { 
      if (topPost.postId === post.postId) {
        loginUser.topPosts[ind] = post;
        foundTopPost = true;
        break;
      }
      ind ++;
    }
  }
  if (!foundTopPost) {
    storedState = getState(SEARCH);
    hashTagPosts = storedState ? storedState.hashTagPosts : null;
    if (hashTagPosts) {
      ind = 0;
      for (let hashTagPost of hashTagPosts) {
        if (hashTagPost.postId === post.postId) {
          hashTagPosts[ind] = post;
          storeState(SEARCH, storedState);
          break;
        }
        ind ++;
      }
    }
  }
}

export function replacePostInAnyUserPosts(post, anyUser) {
  let ind = 0;

  if (anyUser.hasOwnProperty('userPosts')) {
    for (let userPost of anyUser.userPosts) { 
      if (userPost.postId === post.postId) {
        anyUser.userPosts[ind] = post;
        break;
      }
      ind ++;
    }
  }
}

export function updateCommentsPostInMemory(that, post, comments, isAddUserPicture) {
  let anyUser = that.state.anyUser;
  let loginUser = that.state.loginUser;
   
  post.comments = comments;
  post.commentsCount = comments.length;
  if (isAddUserPicture) {
    addUserPicture(post, 'comments');
  }
  if (anyUser) {	
    // we need to propogate the update
    replacePostInTopPosts(post, loginUser);
  }	                              
}

export function getDevice() {
  let device;

  if (/ipad/i.test(navigator.userAgent)) {
    // iPad and Mac have practicaly the same 'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko)'
    device = Const.IPAD; // at this point only in emulator
  }
  else if (/iphone/i.test(navigator.userAgent)) {
    device = Const.IPHONE; 
  } 
  else if (/tablet/i.test(navigator.userAgent)) {
    device = Const.TABLET;  // at this point only in emulator
  } 
  else if (/android/i.test(navigator.userAgent)) {
    device = Const.ANDROID; // phone or tablet
  }
  else if (/macintosh/i.test(navigator.userAgent)) {
    if (navigator.maxTouchPoints > 1) {
      device = Const.IPAD; 
    }
    else {
      device = Const.MAC_DESKTOP;
    }
  }
  else {
    device = Const.WIN_DESKTOP; 
  }
  return device ;
}

export function toolTippy(Component, dataTip, placement, tooltipDelay, iconClassname, clickHandler) {
  let device = getDevice();
  let isImage = typeof Component === 'string';
  const renderContent = () => {
    return isImage ? (
      <img src={Component} className={iconClassname} alt="Avatar" onClick={clickHandler} />
    ) : (
      <span><Component onClick={clickHandler} className={iconClassname}/></span>
    );
  };
  const renderNonTippyContent = () => {
    return isImage ? (
      <img src={Component} className={iconClassname} alt="Avatar" onClick={clickHandler} />
    ) : (
      <Component onClick={clickHandler} className={iconClassname}/>
    );
  };

  if ((device === Const.WIN_DESKTOP) || (device === Const.MAC_DESKTOP)) {
    return (
      <Tippy 
        content={dataTip} 
        theme='medeata-icon-desktop' 
        arrow={roundArrow}
        placement={placement}
        delay={tooltipDelay}
      >
        { renderContent() }
      </Tippy>
    );
  } else {
    return renderNonTippyContent();
  }
};

export function createIconComponent(myIcon) {
  // currently noy in use
  return React.forwardRef((props, ref) => (
    <span ref={ref}>
      {myIcon}
    </span>
  ));
};

export function getElementFontSize(elementId, text) {
  let el = document.getElementById(elementId);
  let fontSizeString;
  let fontSize; 

  if (el && (text === '')) {
    // using (text === '') condition not to print the logs for this tool when it's not needed
    fontSizeString = window.getComputedStyle(el, null).getPropertyValue('font-size');
    fontSize = parseFloat(fontSizeString); 
    console.log('--- ' + text + ' ' + fontSize);
  }
}

export function getLastCommentOfLoginUser(clickedPost, loginUser) {
  let lastCommentOfLoginUser = null;

  if (clickedPost.hasOwnProperty('comments')) {
    for (let comment of clickedPost.comments) {
      if (comment.userId === loginUser.userId) {
        lastCommentOfLoginUser = comment;
        break;
      }
    }
  }
  return lastCommentOfLoginUser;
}

export function setSharedPostFlag(option) {
  sharedPostFlag = option;
}

export function getSharedPostFlag() {
  return sharedPostFlag;
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function replaceUnderscoredWordsWithHashtags(post) {
  const regex = /_(\w+)_/g;
  
  post.post = post.post.replace(regex, (_, word) => `#${word}`);
}

export function isPhoneLayout() {
	let phoneLayoutDetected = isPhonePortretLayout() || isPhoneLandscapeLayout();

	return phoneLayoutDetected;
}

export function isPhonePortretLayout() {
  // width does not depend on browser interface elements height
	let phoneMedia = window.matchMedia(Const.PHONE_MEDIA_INTERVAL);
	let phoneInPortretMode = phoneMedia.matches; 
  let device = getDevice();
  let isIphone = (device === Const.IPHONE) ? true : false;
  let iPhonePortretMode = isIphone && (window.innerWidth < window.innerHeight);

	return phoneInPortretMode || iPhonePortretMode;
}
  
export function isPhoneLandscapeLayout() {
	// for android devices window.innerHeight = window.outerHeight (browser interface elements height 
	// is not detected) so in browser  we can get android small tablets here as well as phones
	let phoneInlandscapeMode = (window.innerWidth > window.innerHeight) && (window.innerHeight <= Const.PHONE_MEDIA_MAX);
  let device = getDevice();
  let isIphone = (device === Const.IPHONE) ? true : false;
  let iPhoneInlandscapeMode = isIphone && (window.innerWidth > window.innerHeight);
  
	return phoneInlandscapeMode || iPhoneInlandscapeMode;
}

export function isBrowsingMode(loginUser) {
  let isBrowsing = false;

  if (loginUser.testDrive  && (loginUser.userName === Const.MEDEATA_CONCIERGE)) {
    isBrowsing = true;
  }
  return isBrowsing;
}
