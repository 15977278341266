import { Row, Col, Card} from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBModal, MDBModalBody } from 'mdbreact';
import '../css/bootstrap.css';
import { Nav, NavItem } from 'reactstrap';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import Toast from 'react-bootstrap/Toast';
import Navi from '../components/nav';
import Medeata from '../images/Medeata200blue.svg';
import MedeataSymbol from '../images/Medeata200blueSymbol.svg';
import GetLikes from '../components/getData/GetLikes';
import GetChannels from '../components/getData/GetChannels';
import GetMenu from '../components/getData/GetMenu';
import ShareDropdown from '../components/ShareDropdown';
import { getUserPictureClassName, getClassNameForWrapDiv, personImayFollow, 
         contactUs, followingFollowersCondition, toolTippy } from '../components/Utils.js';
import { loadingImage } from '../components/ScrollUtils.js';
import Const from '../components/Const';
import { iOSstatusBarPadding } from './CapacitorUtils';
import  { postsAndFollowersClassNames } from './PostUtils.js';
import { getLoginUserJsonWithData } from '../components/getData/GetLoginUserJson';
import {
	TwitterShareButton,
	TelegramShareButton,
	WhatsappShareButton,

	TwitterIcon,
	TelegramIcon,
	WhatsappIcon,

} from "react-share"; // https://github.com/nygardk/react-share/

export function getFullHomePage(canProcessContactUs, returnTo, topPosts, loginUser, that ) {
  //	Home Page or Shared Post renedering for logged in user 	
  const navParams = { 
    loginUser : loginUser, 
    that : that
  }
  const anyUserComponent = '/User';
  const multipleProps = { 
    loginUser : loginUser, 
    userAndHisAssosiatedUsers : loginUser
  };
  let styleObj = {};
  const containerName = getClassNameForWrapDiv(loginUser, null, that);
  const pfClassNames = postsAndFollowersClassNames(containerName);

  if (topPosts.length === 1) {
    // only takes place with one shared post
    styleObj = {
      height : '100vh'
    }
  }

  return (      
    <>
      <div>
        <Navi navParams = { navParams } />
      </div>

      <div id='container' style = {styleObj} className={ pfClassNames.afterAppWrapCenterDiv }>
        <Row>
          <div id='posts' ref={that.postsContainerRef} className={ pfClassNames.postsDiv }>
            { 
              topPosts.map ( topPost => 
                <div key={topPost.postId}>
                  {
                    !filterBlockedPosts(topPost, that) &&
                    <div className="card-wrapper" key={topPost.postId}>
                      <Card data-item="true" className="postcard">
                        <Link to={{ pathname: anyUserComponent, params: { personIFollow : personImayFollow(topPost.userName, topPost.userPicture), loginUser} }}>
                          <Row className="row-margin-0 p-2">
                            <div className={getUserPictureClassName(topPost)}>
                            {
                              topPost.hasOwnProperty('userPicture') 
                              ? <img src= {topPost.userPicture} className="" alt="Avatar" />
                              : <img src={ Const.DEFAULT_USER_PICTURE } className="" alt="Avatar" />
                                }
                            </div>  
                            <div className="col-10">
                              <p className="mb-0 bold post-username"> { topPost.userName } </p>
                              <p className="small mb-0">{ topPost.time }</p>
                            </div>
                          </Row>
                        </Link>
                    
                        {
                          topPost.hasOwnProperty('postImg') &&
                          <div className="image-parent" onTouchStart={that.onTouchStartHandler} 
                            onTouchEnd={that.onTouchEndtHandler} onTouchMove={that.onTouchMoveHandler}>
                            <img style={{height: topPost.imgHeight}} src= { topPost.postImg } alt={ Const.POST_IMAGE_ALT }></img>
                          </div>
                        }

                        <Row>
                          <p className="p-2 mb-0 poststyle" > { topPost.post } </p>
                        </Row>			
                  
                        <Row className="icon-div">
                          <Col xs={3} className="mobile-col">	
                            <GetLikes getLikesParams = { {clickedPost : topPost, viewOnly : false, loginUser : loginUser, that : that }}/> 
                          </Col>
                          <Col xs={3} className="mobile-col">
                            <Link to={{ pathname: '/Comments', params: { clikedPost : topPost, returnTo : returnTo, 
                                      viewOnly : false, anyUser : null, loginUser } }}>
                              <div className="left">
                                { toolTippy(FaIcons.FaRegComment, "Comment", "right", [Const.DELAY, 0], "home-icons-medium", null) }
                              </div>
                              <div className="left">
                                <p className="icon-count medium mb-0"> {topPost.commentsCount} </p>
                              </div>			
                            </Link>   
                          </Col>
                          <Col xs={5} className="mobile-col">	
                            <ShareDropdown postSharingParms = { getPostSharingParms(topPost, loginUser) } /> 
                          </Col>  
                          <Col xs={1} className="mobile-col">	
                            <GetMenu menuParms = { getMenuParms(topPost, that) }/>
                          </Col>                  
                        </Row>
                      </Card>
                    </div>
                  
                  }
                </div>
              ) 
            }  

            <div className="center">
              {
                showLoadingImage(loginUser, that)
              }
            </div>
          </div>

          { 
            /* FOLLOWING / FOLLOWERS*/ 
            getRightDiv(loginUser, multipleProps, pfClassNames, that) 
          }

        </Row>
        
        {
          canProcessContactUs &&
          contactUs()
        }
      </div>
    </>
  );
}

export function getRightDiv(loginUser, multipleProps, pfClassNames, that) {
  if (followingFollowersCondition(loginUser, null, that))  {
    return (
      <div id='followersWrapper' className={ pfClassNames.rightDiv }>
        {
          <div id="rightDiv"  body-scroll-lock-ignore="true">
            <div ref={ that.followersRef } id="folowerScroll" body-scroll-lock-ignore="true" className={ pfClassNames.scroll } onMouseOver={that.onMouseOverHandler} onMouseOut={that.onMouseOutHandler} >
              <GetChannels multipleProps = { multipleProps } />
            </div>
          </div>
        }
      </div>
    )
  } 
  else {
    return (
      <div id='followersWrapper'/>
    )
  }
}

export function getHashTagedPostsPage(returnTo, topPosts, loginUser, anyUser, that ) {
  const anyUserComponent = '/User';
  const pfClassNames = postsAndFollowersClassNames('');

 return (      
   <>
      <Row  className = 'center'>
        <div id='posts' ref={that.postsContainerRef} className={ pfClassNames.postsDiv }>
          { 
            topPosts.map ( topPost => 
              <div key={topPost.postId}>
                {
                  !filterBlockedPosts(topPost, that) &&
                  <div className="card-wrapper" key={topPost.postId}>
                    <Card data-item="true" className="postcard">
                      {
                        getLinkToPostAutor(anyUserComponent, topPost, loginUser)
                      }

                      {
                        topPost.hasOwnProperty('postImg') &&
                        <div className="image-parent" onTouchStart={that.onTouchStartHandler} 
                          onTouchEnd={that.onTouchEndtHandler} onTouchMove={that.onTouchMoveHandler}>
                          <img style={{height: topPost.imgHeight}} src= { topPost.postImg } alt={ Const.POST_IMAGE_ALT }></img>
                        </div>
                      }

                      <Row>
                        <p className="p-2 mb-0 poststyle" > { topPost.post } </p>
                      </Row>			
                
                      <Row className="icon-div p-2">
                        <Col xs={3} className="mobile-col">	
                          <GetLikes getLikesParams = { {clickedPost : topPost, viewOnly : false, loginUser : loginUser, that : that, anyUser : anyUser }}/> 
                        </Col>
                        <Col xs={3} className="mobile-col">
                          <Link to={{ pathname: '/Comments', params: { clikedPost : topPost, returnTo : returnTo, 
                                    viewOnly : false, anyUser : anyUser, loginUser } }}>
                            <div className="left">
                              { toolTippy(FaIcons.FaRegComment, "Comment", "right", [Const.DELAY, 0], "home-icons-medium", null) }
                            </div>
                            <div className="left">
                              <p className="icon-count medium mb-0"> {topPost.commentsCount} </p>
                            </div>			
                          </Link>   
                        </Col>
                        <Col xs={5} className="mobile-col">	
                          <ShareDropdown postSharingParms = { getPostSharingParms(topPost, loginUser) } /> 
                        </Col>  
                        <Col xs={1} className="mobile-col">	
                          <GetMenu menuParms = { getMenuParms(topPost, that) }/>
                        </Col>                  
                      </Row>
                    </Card>
                  </div>
                }
              </div>
            ) 
          }  
          <div className="center">
          {
            that.showLoadingImage()
          }				
          </div>
        </div>
      </Row>
   </>
 );
}

export function getLinkToPostAutor(anyUserComponent, topPost, loginUser) {
  if ((topPost.userName !== loginUser.userName) ) { 
      return (
                <Link to={{ pathname: anyUserComponent, params: { personIFollow : personImayFollow(topPost.userName, topPost.userPicture), loginUser} }}>
                  <Row className="row-margin-0 p-2">
                    <div className={getUserPictureClassName(topPost)}>
                    {
                      topPost.hasOwnProperty('userPicture') 
                      ? <img src= {topPost.userPicture} className="" alt="Avatar" />
                      : <img src={ Const.DEFAULT_USER_PICTURE } className="" alt="Avatar" />
                        }
                    </div>  
                    <div className="col-10">
                      <p className="mb-0 bold post-username"> { topPost.userName } </p>
                      <p className="small mb-0">{ topPost.time }</p>
                    </div>
                  </Row>
              </Link>
            )
  }
  else {
        return (
                <Row className="row-margin-0 p-2">
                  <div className={getUserPictureClassName(loginUser)}>
                  {
                    topPost.hasOwnProperty('userPicture') 
                    ? <img src= {topPost.userPicture} className="" alt="Avatar" />
                    : <img src={ Const.DEFAULT_USER_PICTURE } className="" alt="Avatar" />
                      }
                  </div>  
                  <div className="col-10">
                    <p className="mb-0 bold post-username">Me</p>
                    <p className="small mb-0">{ topPost.time }</p>
                  </div>
                </Row>
              )
    }
}

export function showLoadingImage(loginUser, that) {
  if ((loginUser.hasOwnProperty('loadMore') && loginUser.loadMore.isFinalPage) || 
       // no loading image for shared post obviously
       (loginUser.hasOwnProperty('sharedPost'))) {
    return null;
  }
  else {
    return loadingImage(that);
  }
}

export function getSharedPage(sharedPostId, onePost, loginUser, that) {
  // Shared Post renedering for the outside or logged out user 	
  let userComments;

  if (!onePost) {
    return <div className="bkgrnd" ></div>;
  }
  userComments = onePost.comments;
  return (
    <>
      {
        iOSstatusBarPadding()
      }

      <div className="after-app-wrap-center-div sharedPost">
      {
        <Nav className="pl-0 pr-0">
				<Col md={6} sm={6} xs={3} className="pl-0">
					<NavItem style={{justifyContent: 'left'}}>
						<img src={ Medeata } alt={ Const.MEDEATA_IMAGE_ALT } className = "medeata-desktop" style={{float: 'left', position: 'relative'}}  />
						<img src={ MedeataSymbol } alt={ Const.MEDEATA_IMAGE_ALT } className = "medeata-mobile" style={{position: 'relative'}} />
					</NavItem> 				
				</Col>
				<Col md={6} sm={6} xs={9} className="pr-0">
					<div className="buttonsDiv">	
						{
							that.getAppButton(sharedPostId)
						} 					
            <div id='explore' className="btn btn-info buttonsClass" disabled = { true } onClick={ (event) => handleTestDriveClick(event, that) }>{Const.EXPLORE}</div>
					</div>
				</Col>
			  </Nav>
      }

      {
        <div className="card-wrapper">
          <Card data-item="true" className="postcard">
            <Row className="row-margin-0 p-2">
              <div className={getUserPictureClassName(onePost)}>
                {
                  onePost.hasOwnProperty('userPicture') 
                  ? <img src= {onePost.userPicture} className="" alt="Avatar" />
                  : <img src={ Const.DEFAULT_USER_PICTURE } className="" alt="Avatar" />
                }
              </div>  
              <div className="col-10">
                <p className="mb-0 bold"> { onePost.userName } </p>
                <p className="small mb-0">{ onePost.time }</p>
              </div>
            </Row>
          
            <div>
              {
                onePost.hasOwnProperty('postImg') &&
                <div className="image-parent" onTouchStart={that.onTouchStartHandler} 
                  onTouchEnd={that.onTouchEndtHandler} onTouchMove={that.onTouchMoveHandler}>
                  <img style={{height: onePost.imgHeight}} src= { onePost.postImg } alt={ Const.POST_IMAGE_ALT }></img>
                </div>
              }
              <p className="p-2 mb-0" > { onePost.post } </p>
            </div>			
          
            <Row className="icon-div p-2">
              <Col xs={3} className="mobile-col">	
                
                <div> 
                  <div className="left">
                    {
                      that.getTheRightHeart()
                    } 
                  </div>  
                  <div className="left">
                    <p className="icon-count medium mb-0 left" > {onePost.likesCount} <span className="sams-fold"></span> </p>
                  </div>
                </div>
              </Col>
              <Col xs={3} className="mobile-col">
                <div>
                  <div className="left">
                    <FaIcons.FaRegComment className = "home-icons-medium" />
                  </div>
                  <div className="left">
                    <p className="icon-count medium mb-0"> {onePost.commentsCount} </p>
                  </div>
                </div>		
              </Col>
              <Col xs={5} className="mobile-col">	
                <div>
                  <div className="left">
                    <ShareDropdown postSharingParms = { getPostSharingParms(onePost, loginUser) } /> 
                  </div>
                </div>									
              </Col>  
            </Row>
          </Card>
        </div>
      }

      {
        (!loginUser && (onePost.commentsCount > 0)) &&
        <div className = "sharedComments">
          { userComments.map( userComment => 
            <Row key={ userComment.commentId }> 										
              {
                <div className={getUserPictureClassName(userComment)}>
                  <img src= { userComment.userPicture } alt="Avatar" />
                </div>
              }
              
              <div className="triangle-left">
                <div className="inner-triangle"></div>
              </div>
            
              <div className="talkbubble">											                                          
                <p className = "mb-0 bold"> { userComment.userName } </p>
                <p className = "small">{ userComment.time} </p>
                <p className = "mb-0"> { userComment.comment } </p>														
              </div>  																			
            </Row>  
          )}                      
        </div>
      }
      </div>
    </>
  );
}

export function filterBlockedPosts(post, that) {
  let loginUser = that.state.loginUser;
  let cond = false;
  
  if (loginUser.hasOwnProperty('blockedPostIds')) {
    cond = loginUser.blockedPostIds.includes(post.postId);
  }
  return cond;
}

export function getMenuParms(post, that) {
  let menuParams = { 
    loginUser : that.state.loginUser, 
    post : post,
    that : that
  };	

  return menuParams;
}

export function getPostSharingParms(post, loginUser) {
  let postSharingParams = { 
    sharePost : post,
    loginUser : loginUser
  };	

  return postSharingParams;
}

export  async function handleTestDriveClick(event, that) {
  let userName = Const.MEDEATA_CONCIERGE;

  event.preventDefault();
  enterBrowseMode(that, userName);
}

export  async function enterBrowseMode(that, userName) {
  let exploreButton;
  let data = {
    user_name : userName, 
    ex : '1'
  };

  exploreButton = document.getElementById('explore');
  if (exploreButton) {
    exploreButton.style.opacity = Const.REDUCED_OPACITY;
  }
  else {
    that.setState({ testDrive : true} );
  }
  await getLoginUserJsonWithData(that, data);
}

export function getToast(show, that) {
  return (
		<Row>
			<Toast onClose={() => that.setShow(false)} show={show}  delay={3000} autohide style={{bottom:'0'}} className="myToast" >
				<Toast.Body>Success! Link copied to clipboard 
					<MdIcons.MdClose id="X" className="modal-close pointer-view right" style={{height: '25px'}}/>
				</Toast.Body>
			</Toast>
		</Row>
	)
}

export function getShareDropDownDeskTop(sharePost, show, that) {
  // Can be done by a logged in user ou outside user	
  return (
    <>		
      {
       getToast(show, that)
      }
  
      <div className="menu-bar">
        <div className="menu-bar-item" ref={that.ref}>
          <div className="menu-bar-link link pointer-view" href="#" onClick={that.handleDeskTopClick} >
              <div className="left">
                { toolTippy(AiIcons.AiOutlineShareAlt, "Share Post", "right", [Const.DELAY, 0], "home-icons-medium home-icons-font", null) }
              </div>
              <div className="left">
                <p className="icon-count medium mb-0"> {sharePost.sharesCount} </p>  
              </div>
          </div>
        
          <div className={"mega-menu " + that.state.clicked}>
            <div className="mega-menu-share">						
                <div>
                  <div className="social pointer-view" >
                    <div className="left">
                      <AiIcons.AiOutlineLink className="home-icons-medium"/>
                    </div>
                    <div className="left">
                      <p className="icon-count medium mb-0 pl-1" onClick= {that.handleClickToCopyLink}>Copy URL</p>
                    </div>
                  </div>
                </div>							
            </div>
          </div>
        </div>
      </div>
    </>    
  );
}

export function getShareDropDownMobil(modal, sharePost, sharedPostUrl, show, that) {
  // Can be done by a logged in user ou outside user
  return (
    <>
		  {
         getToast(show, that)
      }

			 	<div className="menu-bar-link link shared" href="#" onClick= {that.toggle } >
					<div className="left">
						<AiIcons.AiOutlineShareAlt className="home-icons-medium" />
					</div>
					<div className="left">
						<p className="icon-count medium mb-0"> {sharePost.sharesCount} </p>  
					</div>
            </div>

				{
					modal && 
					<MDBContainer onClick={that.handleJustClosingClick}>
						<MDBModal id="sharedModal" isOpen={modal} toggle={that.toggle} autoFocus={false}  >
							
							<MDBModalBody id="main"  >       
								<form>
									<div className="center">
										<div className="social">
											<div className="left copy">
												<AiIcons.AiOutlineLink className="home-icons-medium" onClick= {that.handleClickToCopyLink} />
											</div>
										</div>
										<div className="social" onClick={that.handleClick}>
											<TwitterShareButton
												url = { sharedPostUrl }
												>
												<TwitterIcon round />
											</TwitterShareButton>
										</div>
										<div className="social" onClick={that.handleClick}>
											<WhatsappShareButton									
												url = { sharedPostUrl }
												>
												<WhatsappIcon round /> 
											</WhatsappShareButton>
										</div>
										<div className="social" onClick={that.handleClick}>
											<TelegramShareButton
												url = { sharedPostUrl }
												>
												<TelegramIcon round />
											</TelegramShareButton>
										</div>
									</div>
								</form>
							</MDBModalBody> 
						</MDBModal>
					</MDBContainer>
				} 
		</>   
  )
}